<!--地标书画展列表-->
<template>
  <div class="paintingDetailDiv">
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <div class="paintingDiv">
      <div class="notice-title">
        <span>地标书画展</span>
      </div>
      <div class="paintingContent">
        <div class="paintingList cursorPointer" v-for="(item, index) in paintingList" :key="index" @click="toDetail(item)">
          <div class="paintingImg">
            <img :src="item.contentImg" alt="">
          </div>
          <div class="paintingName">
            <div class="title">{{item.contentTitle}}</div>
            <!--这里接口没有给返回作者-->
            <!--<div class="author">{{item.author}}</div>-->
          </div>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="page">
      <el-pagination
        :page-sizes="[12, 20, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="handleSizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal">
      </el-pagination>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 10px"></div>
  </div>
</template>

<script>
import axios from 'axios'
import topPathList from '@/components/topPathList'
export default {
  name: "paintingDetail",
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标书画展',
          path: '/painting/paintingDetail'
        },
      ],
      paintingList: [],
      page: 1,
      tableTotal: 0,
      pageSize: 12,
    }
  },
  methods: {
    //查询详情
    getPaintingDetail() {
      let param = {
        id: "744967562642857984",
        current: this.page,
        size: this.pageSize
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/graphicdisplay/graphicList',
        data: param
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.paintingList = res.data.data.records
          this.tableTotal = res.data.data.total
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    toDetail(item) {
      let pathObj = this.pathObj;
      pathObj.push({
        name: '地标书画展详情',
        path: '/details'
      })

      this.$router.push({
        path: '/details',
        query: {
          pathObj: pathObj,
          contentId: item.contentId,
          typeId: '744967562642857984'
        }
      });
    },

    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getPaintingDetail();
    },
    pageChange(val) {
      this.page = val
      this.getPaintingDetail();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getPaintingDetail()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getPaintingDetail()
    },
  },
  mounted() {
    this.getPaintingDetail()
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .paintingDetailDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }

  .paintingDiv {
    background: #FFFFFF;
    width: 1200px;
    margin: 30px auto;
    overflow: hidden;
    .notice-title {
      clear: both;
      font-size: 35px;
      font-weight: bold;
      margin-top: 20px;
    }
    .paintingContent {
      min-height: 347px;
      width: 1200px;
      margin: 30px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .paintingList {
        margin: 20px;
        img {
          width: 360px;
          height: 460px;
        }
        .paintingName {
          width: 360px;
          display: flex;
          justify-content: space-around;
          font-size: 20px;
          font-weight: 500;
          color: #000000;
          .title {

          }
          .author {

          }
        }
      }
    }
  }

  .page {
    margin: 20px;
  }

</style>
